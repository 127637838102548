import { wrapRootElement as wrap } from './rootWrapper'
import Rellax from './src/vendor/rellax-patched/rellax'

import './src/scss/styles.scss'

export const wrapRootElement = wrap

export const onRouteUpdate = () => {
  if (process.env.NODE_ENV === `production` || `development`) {
    if (window !== undefined) {
      window.initRellaxScrolls = initRellaxScrolls
    }

    setTimeout(() => {
      if (window && document) {
        const imagesLoaded = Array.from(document.querySelectorAll('img'))

        const updateStatus = (allImages) => {
          if (
            allImages
              .map((image) => image.complete)
              .every((item) => item === true)
          ) {
            initRellaxScrolls()
          }
        }

        if (imagesLoaded.length === 0) {
          initRellaxScrolls()
          return
        }

        imagesLoaded.forEach((image) => {
          image.addEventListener('load', () => updateStatus(imagesLoaded), {
            once: true,
          })
          image.addEventListener('error', () => updateStatus(imagesLoaded), {
            once: true,
          })
        })
        updateStatus(imagesLoaded)
      }
    }, 2500)
  }
}

const initRellaxScrolls = () => {
  if (window.rellax_center) {
    window.rellax_center.destroy()
  }
  if (window.rellax) {
    window.rellax.destroy()
  }

  if (document.querySelectorAll('[data-rellax-center]').length > 0) {
    window.rellax_center = new Rellax('[data-rellax-center]', {
      center: true,
    })
  }

  if (document.querySelectorAll('[data-rellax]').length > 0) {
    window.rellax = new Rellax('[data-rellax]')
  }
}
