import React, { createContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import '../i18n/config'

export const NavContext = createContext()

const NavContextProvider = ({ children }) => {
  const [navData, setNavData] = useState(null)

  const { i18n } = useTranslation()

  useEffect(() => {
    fetch(`${window.location.origin}/navigation/${i18n.language}.json`)
      .then((response) => response.json())
      .then((data) => setNavData(data))
  }, [i18n.language])

  return (
    <NavContext.Provider value={{ navData }}>{children}</NavContext.Provider>
  )
}

export default NavContextProvider
