export const onRouteUpdate = () => {
  if (process.env.NODE_ENV === `production` || `development`) {
    setTimeout(() => {
      if (window && window.dataLayer && window.dataLayer.push) {
        window.dataLayer.push({ event: `gatsby-route-change` })
      }
    }, 50)
  }
}
export const disableCorePrefetching = () => true
