import React from 'react'
import NavContextProvider from './src/context/NavContext'

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <NavContextProvider>{element}</NavContextProvider>
    </>
  )
}
